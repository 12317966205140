import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetPlayerTransactionPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PaymentStatus, PaymentType } from '@/globalTypes';
import { assert } from '@/utils/error';
import { SanityPlayerPaymentsBlockFragment } from './__generated__/SanityPlayerPaymentsBlockFragment';
import usePlayerPayments from './usePlayerPayments';
import usePlayerPaymentsReport from './usePlayerPaymentsReport';

export const Fragment = graphql`
  fragment SanityPlayerPaymentsBlockFragment on SanityPlayerPaymentsBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerPaymentsBlock: FC<{
  block: SanityPlayerPaymentsBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerId, 'missing playerId');

  const { t } = useTranslate();

  const {
    payments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerPayments(params.playerId);
  const report = usePlayerPaymentsReport(params.playerId);

  const transactionPath = useGetPlayerTransactionPageLink()(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerPayments',
    data: payments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) =>
      `${transactionPath}?${queryString.stringify({
        rawTransactionId: row.rawPaymentId,
      })}`,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: 'processedAt',
      }),
      s.monetaryValue({
        field: 'initialAmount',
        title: 'Initial Amount',
      }),
      s.stringValue({
        field: 'rawPaymentId',
        title: 'Payment ID',
        filterField: 'rawId',
      }),
      s.monetaryValue({
        field: 'amount',
        title: 'Amount',
      }),
      s.enumValue({
        field: 'paymentType',
        title: 'Payment Type',
        filterField: 'paymentType',
        e: PaymentType,
      }),
      s.enumValue({
        field: 'paymentStatus',
        title: 'Payment Status',
        filterField: 'paymentStatus',
        e: PaymentStatus,
      }),
      s.stringValue({
        field: 'paymentMethod',
        title: 'Payment Method',
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: 'Opening Balance',
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: 'Closing Balance',
      }),
      s.stringValue({
        field: 'instrument',
        title: 'Instrument',
      }),
      s.stringValue({
        field: 'statusMessage',
        title: 'Message',
      }),
      s.stringValue({
        field: 'extTransactionId',
        title: 'Ext. ID',
      }),
    ],
  });
  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerPaymentsBlock;
