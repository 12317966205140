import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useElasticIndexPrefix } from '@/contexts';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerPayments,
  PlayerPaymentsVariables,
} from './__generated__/PlayerPayments';
import { queryParams } from './queryParams';

const PLAYER_PAYMENTS_QUERY = gql`
  query PlayerPayments(
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $from: OffsetDateTime
    $id: String
    $indexPrefix: String
    $last: Int
    $orderBy: PaymentSearchOrder
    $paymentStatus: PaymentStatus
    $paymentType: PaymentType
    $playerId: ID!
    $rawId: String
    $to: OffsetDateTime
  ) {
    player(playerId: $playerId) {
      id
      payments(
        after: $after
        before: $before
        desc: $desc
        first: $first
        from: $from
        id: $id
        indexPrefix: $indexPrefix
        last: $last
        orderBy: $orderBy
        paymentStatus: $paymentStatus
        paymentType: $paymentType
        rawId: $rawId
        to: $to
      ) {
        edges {
          node {
            rawPaymentId
            initialAmount
            amount
            processedAt
            paymentType
            paymentStatus
            openingBalance
            closingBalance
            instrument
            paymentMethod
            statusMessage
            extTransactionId
            currency
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerPayments(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const { indexPrefix } = useElasticIndexPrefix();
  const [{ data, fetching }, refresh] = useQuery<
    PlayerPayments,
    PlayerPaymentsVariables
  >({
    query: PLAYER_PAYMENTS_QUERY,
    variables: {
      playerId,
      indexPrefix,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.payments.pageInfo),
    payments: data?.player.payments?.edges?.map((edge) => edge?.node),
  };
}
